export function loadFirebaseCredentials() {
  if (isDevelopmentEnvironment()) {
    return importFirebaseCredentialsFromFile();
  }
  return loadFirebaseCredentialsFromEnv();
}

function isDevelopmentEnvironment() {
  return process.env.NODE_ENV === 'development';
}

function importFirebaseCredentialsFromFile() {
  // Dynamic imports can be used if you want to import only in development mode.
  // This requires proper configuration and might not work out-of-the-box with all setups.
  return import('./firebaseCredentials.js').then(module => module.firebaseCredentials);
}

function loadFirebaseCredentialsFromEnv() {
  return {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    // measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
  }
}
