import axios from '@axios'

export default {
  state: {
    services: [],
    orgServices: {
      org_id: null,
      services: [],
    },
  },
  getters: {
    getServices(state) {
      return state.services
    },
    getOrgServices(state) {
      return state.orgServices
    },
  },
  mutations: {
    updateServices(state, data) {
      state.services = data
    },
    updateServiceStatusAvailable(state, {index, value}) {
      state.services[index].is_available = value
    },
    setAllServiceAvailableStatusTrue(state) {
      for (const i in state.services) {
        state.services[i].is_available = true
      }
    },
    UPDATE_ORG_SERVICES(state, data) {
      state.orgServices.services = data
    },
    UPDATE_ORG_SERVICES_ORG_ID(state, data) {
      state.orgServices.org_id = data
    },
  },
  actions: {
    updateService(context, formData) {
      return new Promise((resolve, reject) => {
        axios.patch(`workspaces/services/update/${formData.get('service')}`, formData)
          .then(response => {
            // context.commit('updateOneService', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    createNewService(context, formData) {
      return new Promise((resolve, reject) => {
        axios.post(`workspaces/services/create/${formData.get('organization')}`, formData)
          .then(() => resolve(true))
          .catch(error => reject(error))
      })
    },
    addAllServicesInWorkspace(context, { workspace, services }) {
      return new Promise((resolve, reject) => {
        axios.patch(`workspaces/services/add-all/${workspace}`, {
          services,
        })
          .then(() => resolve(true))
          .catch(error => reject(error))
      })
    },
    addServiceForWorkspace(context, { workspace, service }) {
      return new Promise((resolve, reject) => {
        axios.patch(`workspaces/services/add/${workspace}`, {
          service,
        })
          .then(() => resolve(true))
          .catch(error => reject(error))
      })
    },
    removeServiceFromWorkspace(context, { wrs, service }) {
      return new Promise((resolve, reject) => {
        axios.patch(`workspaces/services/remove/${wrs}`, {
          service,
        })
          .then(() => resolve(true))
          .catch(error => reject(error))
      })
    },
    deleteOrgService(context, service) {
      return new Promise((resolve, reject) => {
        axios.delete(`workspaces/services/delete/${service}`)
          .then(() => resolve(true))
          .catch(error => reject(error))
      })
    },
    fetchServicesWorkspace(context, workspace) {
      return new Promise((resolve, reject) => {
        axios.get(`workspaces/services/${workspace}`)
          .then(response => {
            context.commit('updateServices', response.data.services)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchServicesOrganization(context, organization) {
      return new Promise((resolve, reject) => {
        axios.get(`organizations/services/${organization}`)
          .then(response => {
            context.commit('UPDATE_ORG_SERVICES', response.data.services_by_org)
            context.commit('UPDATE_ORG_SERVICES_ORG_ID', organization)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
