import axios from '@axios'

export default {
  state: {
    daysOffWorkspace: {},
    daysOffOrganization: {},
    daysOffWorkspacePagination: {},
    daysOffOrganizationPagination: {},
    daysOffWorkspaceSettingsPagination: {
      page: 1,
      size: 5,
      order: 'id',
      query: '',
    },
    daysOffOrganizationSettingsPagination: {
      page: 1,
      size: 5,
      order: 'id',
      query: '',
    },
  },
  getters: {
    getDaysOffWorkspace(state) {
      return state.daysOffWorkspace
    },
    getDaysOffWorkspacePagination(state) {
      return state.daysOffWorkspacePagination
    },
    getDaysOffWorkspaceSettingsPagination(state) {
      return state.daysOffWorkspaceSettingsPagination
    },
    getDaysOffOrganization(state) {
      return state.daysOffOrganization
    },
    getDaysOffOrganizationPagination(state) {
      return state.daysOffOrganizationPagination
    },
    getDaysOffOrganizationSettingsPagination(state) {
      return state.daysOffOrganizationSettingsPagination
    },
  },
  mutations: {
    UPDATE_DAYS_OFF_WORKSPACE(state, data) {
      state.daysOffWorkspace = data
    },
    UPDATE_DAYS_OFF_WORKSPACE_PAGINATION(state, data) {
      state.daysOffWorkspacePagination = data
    },
    UPDATE_DAYS_OFF_WORKSPACE_SETTINGS_PAGINATION(state, {key, value}) {
      state.daysOffWorkspaceSettingsPagination[key] = value
    },
    UPDATE_DAYS_OFF_ORGANIZATION(state, data) {
      state.daysOffOrganization = data
    },
    UPDATE_DAYS_OFF_ORGANIZATION_PAGINATION(state, data) {
      state.daysOffOrganizationPagination = data
    },
    UPDATE_DAYS_OFF_ORGANIZATION_SETTINGS_PAGINATION(state, {key, value}) {
      state.daysOffOrganizationSettingsPagination[key] = value
    },
  },
  actions: {
    createOrganizationDayOff(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/organizations/holidays/add/${data.owner_id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateOrganizationDayOff(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/organizations/holidays/update/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrganizationDaysOff(context, formData) {
      return new Promise((resolve, reject) => {
        axios.get(`/organizations/holidays/${formData.get('id')}?order=${formData.get('order')}&page=${formData.get('page')}&page_size=${formData.get('size')}&query=${formData.get('query')}`)
          .then(response => {
            context.commit('UPDATE_DAYS_OFF_ORGANIZATION', response.data.result)
            context.commit('UPDATE_DAYS_OFF_ORGANIZATION_PAGINATION', response.data.pagination)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    deleteOrganizationDayOff(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/organizations/holidays/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createWorkspaceDayOff(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/workspaces/days-off/add/${data.owner_id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateWorkspaceDayOff(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/workspaces/days-off/update/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWorkspaceDaysOff(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/workspaces/days-off/${formData.get('id')}?order=${formData.get('order')}&page=${formData.get('page')}&page_size=${formData.get('size')}&query=${formData.get('query')}`)
          .then(response => {
            ctx.commit('UPDATE_DAYS_OFF_WORKSPACE', response.data.result)
            ctx.commit('UPDATE_DAYS_OFF_WORKSPACE_PAGINATION', response.data.pagination)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    moveWorkspaceDayOff(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/workspaces/days-off/move/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteWorkspaceDayOff(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/workspaces/days-off/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
