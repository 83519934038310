import axios from '@axios'

export default {
  state: {
    currency: '',
    userOrganizations: [],
    userOrgWithPermittedAccess: [],
    organizationSettings: {},
    orgCategories: null,
  },
  getters: {
    getCurrency(state) {
      return state.currency
    },
    getUserOrganizations(state) {
      return state.userOrganizations
    },
    getUserOrgWithPermittedAccess(state) {
      return state.userOrgWithPermittedAccess
    },
    getOrganizationSettings(state) {
      return state.organizationSettings
    },
    getOrgCategories(state) {
      return state.orgCategories
    },
  },
  mutations: {
    updateCurrency(state, data) {
      state.currency = data
    },
    updateUserOrganizations(state, data) {
      state.userOrganizations = data
    },
    updateUserOrgWithPermittedAccess(state, data) {
      state.userOrgWithPermittedAccess = data
    },
    UPDATE_ORG_SETTINGS(state, data) {
      return new Promise((resolve) => {
        state.organizationSettings = data
        resolve(true)
      })
    },
    UPDATE_ORG_WORKSPACES(state, data) {
      state.organizationSettings.workspace_by_org = data
    },
    updateOrgCategories(state, data) {
      state.orgCategories = data
    },
  },
  actions: {
    createNewOrganization(context, formData) {
      return new Promise((resolve, reject) => {
        axios.post('/organizations/create/', formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUserOrganizations(context) {
      return new Promise((resolve, reject) => {
        axios.get('/organizations/my/').then(response => {
          context.commit('updateUserOrganizations', response.data)
          if (response.data.length !== 0) {
            context.commit('updateCurrency', response.data[0].currency)
          }
          resolve(response.data)
        }).catch(error => reject(error))
      })
    },
    fetchUserOrgWithPermittedAccess(context) {
      return new Promise((resolve, reject) => {
        axios.get('/organizations/with-permitted-access/').then(response => {
          context.commit('updateUserOrgWithPermittedAccess', response.data)
          resolve(response.data)
        }).catch(error => reject(error))
      })
    },
    setOrgSettings(context, organization) {
      return new Promise((resolve) => {
        context.commit('UPDATE_ORG_SETTINGS', organization)
        // context.commit('updateOrgWorkspaces', organization.workspace_by_org)
        // if (organization.workspace_by_org.length === 0) {
        //   store.commit('calendar/SET_SELECTED_EVENTS', [])
        // }
        resolve(true)
      })
    },
    // eslint-disable-next-line consistent-return
    fetchOrganizationSettings(context, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/organizations/settings/${id}`)
          .then(response => {
            context.commit('UPDATE_ORG_SETTINGS', response.data)
            context.commit('updateCurrency', response.data.currency)
            // context.commit('updateOrgWorkspaces', response.data.workspace_by_org)
            // if (response.data.workspace_by_org.length === 0) {
            //   store.commit('calendar/SET_SELECTED_EVENTS', [])
            // }
            // context.dispatch('setOrgName', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    // eslint-disable-next-line consistent-return
    fetchOrgCategories(context) {
      if (context.state.orgCategories === null) {
        return new Promise((resolve, reject) => {
          axios.get('/organizations/categories/')
            .then(response => {
              context.commit('updateOrgCategories', response.data)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      }
    },
    setSettings(context, formData) {
      return new Promise((resolve, reject) => {
        axios.patch(`/organizations/update/${formData.get('id')}`, formData)
          .then(response => {
            context.commit('UPDATE_ORG_SETTINGS', response.data)
            // context.commit('updateCurrency', response.data.currency)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    setSettingsGetObjectData(context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`/organizations/update/${data.id}`, data)
          .then(response => {
            context.commit('UPDATE_ORG_SETTINGS', response.data)
            // context.commit('updateCurrency', response.data.currency)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    deleteOrganization(context, { formData, organizationId }) {
      return new Promise((resolve, reject) => {
        axios.patch(`/organizations/delete/${organizationId}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
