import { $themeConfig } from '@themeConfig'

export default {
  state: {
    connected: false,
    websocket: null,
    play: false,
  },
  getters: {
    getPlay(state) {
      return state.play
    },
  },
  mutations: {
    UPDATE_WEBSOCKET(state, data) {
      state.websocket = data
    },
    UPDATE_CONNECTED(state, data) {
      state.connected = data
    },
    UPDATE_PLAY(state) {
      state.play = !state.play
    },
  },
  actions: {
    async connect(context, user) {
      if (!context.state.connected) {
        let wsProtocol = 'wss://'
        if (window.location.protocol !== 'https:') {
          wsProtocol = 'ws://'
        }
        await context.commit('UPDATE_WEBSOCKET', new WebSocket(
          `${wsProtocol}${$themeConfig.app.backendHostname}/ws/notification/?${user}`,
        ))
        // eslint-disable-next-line no-param-reassign
        context.state.websocket.onopen = () => {
          context.commit('UPDATE_CONNECTED', true)
          // eslint-disable-next-line no-param-reassign
          context.state.websocket.onmessage = ({ data }) => {
            const notification = JSON.parse(data)
            const booking = {
              type: notification.type,
              org_name: notification.org_name,
              title: notification.title,
              specialist_name: notification.specialist_name,
              start_time: notification.start_time,
              specialist_avatar: notification.specialist_avatar,
              name: notification.name,
              confirmed: notification.confirmed,
              phone: notification.phone,
              email: notification.email,
              additionally: notification.additionally,
              total_price: notification.total_price,
            }
            context.commit('updatePushNotifications', booking, { root: true })
            if (JSON.parse(localStorage.getItem('sound'))) {
              context.commit('UPDATE_PLAY')
            }
          }
        }
        // eslint-disable-next-line no-param-reassign
        context.state.websocket.onerror = () => {
          context.commit('UPDATE_CONNECTED', false)
        }
      }
    },
    disconnect(context) {
      context.commit('UPDATE_CONNECTED', false)
      context.state.websocket.close()
    },
  },
}
