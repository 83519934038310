import axios from '@axios'

export default {
  state: {
    widget_id: null,
    widget_org_id: null,
    widgetSettings: [],

  },
  getters: {
    getWidgetSettings(state) {
      return state.widgetSettings
    },
    getWidgetOrgId(state) {
      return state.widget_org_id
    },
    getWidgetId(state) {
      return state.widget_id
    },
  },
  mutations: {
    UPDATE_WIDGET_SETTINGS(state, data) {
      state.widgetSettings = data
    },
    UPDATE_ONLY_WIDGET_SETTINGS(state, data) {
        state.widgetSettings.widget = data
    },
    UPDATE_WIDGET_ORG_ID(state, data) {
      state.widget_org_id = data
    },
    UPDATE_WIDGET_ID(state, data) {
      state.widget_id = data
    },
  },
  actions: {
    fetchWidgetSettings(context, uuid) {
      return new Promise((resolve, reject) => {
        axios.get(`/storefront/organizations/widget/${uuid}`)
          .then(response => {
            context.commit('UPDATE_WIDGET_SETTINGS', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    sendWidgetSettings(context, widgetSettings) {
      return new Promise((resolve, reject) => {
        axios.patch(`widget/update/${widgetSettings.get('id')}`, widgetSettings)
          .then(response => {
            context.commit('UPDATE_ONLY_WIDGET_SETTINGS', response.data)
            resolve(true)
          })
          .catch(error => reject(error))
      })
    },
    sendCode(context, emailAndOrg) {
      return new Promise((resolve, reject) => {
        axios.post('email/widget-instructions', emailAndOrg)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
