import axios from '@axios'
import crypto from '@/libs/crypto'

export default {
  state: {
    userData: JSON.parse(crypto.decryptLocalStorageData('userData')) || null,
    userDevices: {},
  },
  getters: {
    getUserDevices(state) {
      return state.userDevices
    },
    getUserData(state) {
      return state.userData
    },
    loggedIn(state) {
      return !!state.userData
    },
  },
  mutations: {
    updateUserData(state, data) {
      state.userData = data
      crypto.encryptDataAndSaveToLocalStorage('userData', data)
      // localStorage.setItem('userData', JSON.stringify(data))
    },
    UPDATE_USER_DEVICES(state, data) {
      state.userDevices = data
    },
    DELETE_USER_DEVICE(state, index) {
      state.userDevices.splice(index, 1)
    },
    UPDATE_USER_DATE_BY_KEY(state, { key, value }) {
      state.userData[key] = value
      crypto.encryptDataAndSaveToLocalStorage('userData', state.userData)
      // localStorage.setItem('userData', JSON.stringify(data))
    },
  },
  actions: {
    setStrictSchedule(context, { bool, userId }) {
      // console.log('setStrictSchedule', bool, userId)
      return new Promise((resolve, reject) => {
        axios.patch(`/profile/settings/update/${userId}`, {
          enforce_strict_timetable_rules: bool,
        }).then(response => {
          context.commit('UPDATE_USER_DATE_BY_KEY', {key: 'enforce_strict_timetable_rules', value: bool})
          resolve(response)
        })
          .catch(error => {
            context.commit('UPDATE_USER_DATE_BY_KEY', {key: 'enforce_strict_timetable_rules', value: !bool})
            reject(error)
          })
      })
    },
    resetPasswordConfirmation(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('/auth/users/reset_password_confirm/', {
          uid: data.uid,
          token: data.token,
          new_password: data.password,
          re_new_password: data.cPassword,
        }).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    resetPassword(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('/auth/users/reset_password/', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // get !!!!!
    fetchUserData(context) {
      return new Promise(((resolve, reject) => {
        axios.get('/auth/users/me/').then(response => {
          context.commit('updateUserData', response.data)
          resolve(response)
        }).catch(error => reject(error))
      }))
    },
    // patch !!!!!
    setupUserData(context, formData) {
      return new Promise((resolve, reject) => {
        axios.patch('/auth/users/me/', formData).then(response => {
          context.commit('updateUserData', response.data)
          resolve(response.data)
        }).catch(error => reject(error))
      })
    },
    deleteAccount(context, { formData, userId }) {
      return new Promise((resolve, reject) => {
        axios.patch(`/profile/delete/${userId}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDevicesOneUser(context, user) {
      return new Promise((resolve, reject) => {
        axios.get(`/profile/devices/${user}`)
          .then(response => {
            context.commit('UPDATE_USER_DEVICES', response.data.auth_token_set)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
