import axios from '@axios'

export default {
  state: {
    clients_base_last_org_id: null,
    clientsBasePagination: {},
    clients: [],
    clientsBaseSettingsPagination: {
      page: 1,
      size: 10,
      order: 'id',
      query: '',
    }
  },
  getters: {
    getClientsBasePagination(state) {
      return state.clientsBasePagination
    },
    getClients(state) {
      return state.clients
    },
    getClientsBaseLastOrgId(state) {
      return state.clients_base_last_org_id
    },
    getClientsBaseSettingsPagination(state) {
      return state.clientsBaseSettingsPagination
    },
  },
  mutations: {
    UPDATE_CLIENTS_PAGINATION(state, data) {
      state.clientsBasePagination = data
    },
    UPDATE_CLIENTS(state, data) {
      state.clients = data
    },
    UPDATE_CLIENTS_BASE_LAST_ORG_ID(state, data) {
      state.clients_base_last_org_id = data
    },
    UPDATE_CLIENTS_BASE_SETTINGS_PAGINATION(state, {key, value}) {
      state.clientsBaseSettingsPagination[key] = value
    },
  },
  actions: {
    addNewClientToClientBase(context, formData) {
      return new Promise((resolve, reject) => {
        axios.post(`/workspaces/clients-base/create/${formData.get('organization')}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateClientInfo(context, formData) {
      return new Promise((resolve, reject) => {
        axios.post(`/workspaces/clients-base/update/${formData.get('client')}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClientBase(context, formData) {
      return new Promise((resolve, reject) => {
        axios.get(`/workspaces/clients-base/${formData.get('organization')}?order=${formData.get('order')}&page=${formData.get('page')}&page_size=${formData.get('size')}&query=${formData.get('query')}`, formData)
          .then(response => {
            context.commit('UPDATE_CLIENTS_BASE_LAST_ORG_ID', formData.get('organization'))
            context.commit('UPDATE_CLIENTS_PAGINATION', response.data.pagination)
            context.commit('UPDATE_CLIENTS', response.data.result)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    downloadClientsForm(context, formData) {
      return new Promise((resolve, reject) => {
        axios.post(`/workspaces/clients-base/export/${formData.get('organization')}`, formData).then(response => {
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    deleteClient(context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`/workspaces/clients-base/delete/${id}`).then(response => {
          resolve(response)
        }).catch(error => reject(error))
      })
    }
  },
}
