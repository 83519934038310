import axios from '@axios'

export default {
  state: {
    orgServicesForBookingPage: [],
    // orgWorkspacesForBookingPage: [],
    // workspaceServicesForBookingPage: [],
  },
  getters: {
    getOrgServicesForBookingPage(state) {
      return state.orgServicesForBookingPage
    },
  },
  mutations: {
    updateOrgServicesForBookingPage(state, data) {
      state.orgServicesForBookingPage = data
    },
    // updateOrgWorkspacesForBookingPage(state, data) {
    //   state.orgWorkspacesForBookingPage = data
    // },
    // updateWorkspaceServicesForBookingPage(state, data) {
    //   state.workspaceServicesForBookingPage = data
    // },
  },
  actions: {
    confirmation(context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`/storefront/records/confirm/${data.record}`).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrgServicesForBookingPage(context, { organization, page, source }) {
      return new Promise((resolve, reject) => {
        axios.get(`/storefront/organizations/${organization}?${source}`)
          .then(response => {
            context.commit('updateOrgServicesForBookingPage', response.data)
            context.commit('updateCurrency', response.data.currency)
            if (page !== 'widget') {
              context.commit('appConfig/UPDATE_SKIN', response.data.background)
            }
            // context.commit('updateLogo', response.data.logo_small)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchTimeslotsForOneWorkspace(context, {workspaceId, serviceId}) {
      return new Promise((resolve, reject) => {
        axios.post(`/storefront/timeslots/multiple-days/${workspaceId}`, {services: [serviceId]})
          .then(response => {
            // context.commit('updateWorkspaceServicesForBookingPage', [])
            resolve(response.data.records_by_workspace)
          })
          .catch(error => reject(error))
      })
    },
    fetchTimeslotsAndCountdownLikeGuest(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/storefront/timeslots/single-day/${data.workspace}`, data)
          .then(response => {
            context.commit('UPDATE_COUNTDOWN', response.data.countdown)
            context.commit('UPDATE_TIMESLOTS', response.data.timeslots)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
