import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

function loadLangUser() {
  const supportedLocales = ['en', 'ru', 'cs', 'fr', 'ge', 'pt', 'es', 'it', 'ja', 'pl', 'sv', 'tr', 'uk', 'hu', 'nl']

  const lang = navigator.language.toLowerCase()

  if (localStorage.getItem('locale')) {
    return localStorage.getItem('locale')
  }

  const matchedLocale = supportedLocales.find(l => lang.startsWith(l))

  if (matchedLocale) {
    localStorage.setItem('locale', matchedLocale)
    return matchedLocale
  }

  localStorage.setItem('locale', 'en')
  return 'en'
}

export default new VueI18n({
  locale: loadLangUser(),
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
})
