import axios from '@axios'

export default {
  state: {
    notifications: [],
  },
  getters: {
    getNotifications(state) {
      return state.notifications
    },
  },
  mutations: {
    updatePushNotifications(state, data) {
      state.notifications.unshift(data)
    },
    updateSetNotifications(state, data) {
      state.notifications = data
    },
    updateDeleteNotifications(state) {
      state.notifications = []
    },
  },
  actions: {
    fetchNotifications(context, user) {
      return new Promise((resolve, reject) => {
        axios.get(`/profile/notifications/${user}`)
          .then(response => {
            context.commit('updateSetNotifications', response.data.users_notification.notification)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    deleteAllNotifications(context, user) {
      context.commit('updateDeleteNotifications')
      return new Promise((resolve, reject) => {
        axios.post('/communication/clean-notifications/', {
          user_id: user,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    // eslint-disable-next-line consistent-return
    createOrDeleteDeviceUser(context, formData) {
      return new Promise((resolve, reject) => {
        axios.post('/communication/update-or-create-device/', formData, {
          headers: { 'Content-Type': 'application/json' },
        })
          .then(response => {
            // context.commit('UPDATE_FIREBASE_INFO', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    sendSupportEmail(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('email/support', data).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
