import axios from '@axios'

export default {
  state: {
    branch_offices_last_org_id: null,
    branchOffices: [],
    activeAddNewBranch: false,
  },
  getters: {
    getActiveNewAddBranch(state) {
      return state.activeAddNewBranch
    },
    getBranchOffices(state) {
      return state.branchOffices
    },
    getBranchOfficesLastOrgId(state) {
      return state.branch_offices_last_org_id
    }
  },
  mutations: {
    updateActiveNewAddBranch(state, bool) {
      state.activeAddNewBranch = bool
    },
    updateBranchOffices(state, data) {
      state.branchOffices = data
    },
    UPDATE_BRANCH_OFFICES_LAST_ORG_ID(state, data) {
      state.branch_offices_last_org_id = data
    }
  },
  actions: {
    fetchBranchOfficesOneOrganization(context, organization) {
      return new Promise((resolve, reject) => {
        axios.get(`/organizations/branch/${organization}`).then(response => {
          context.commit('UPDATE_BRANCH_OFFICES_LAST_ORG_ID', organization)
          context.commit('updateBranchOffices', response.data)
          resolve(true)
        }).catch(error => reject(error))
      })
    },
  },
}
