import axios from '@axios'

export default {
  state: {
    // workCategories: null,
    userWorkspacesWithPermittedAccess: [],
    activeAddWorkspace: false,
  },
  getters: {
    getActiveAddWorkspace(state) {
      return state.activeAddWorkspace
    },
    getUserWorkspacesWithPermittedAccess(state) {
      return state.userWorkspacesWithPermittedAccess
    },
  },
  mutations: {
    updateUserWorkspacesWithPermittedAccess(state, data) {
      state.userWorkspacesWithPermittedAccess = data
    },
    // updateWorkCategories(state, data) {
    //   state.workCategories = data
    // },
    updateActiveAddWorkspace(state, bool) {
      state.activeAddWorkspace = bool
    },
  },
  actions: {
    fetchUserWorkspacesWithPermittedAccess(context) {
      return new Promise((resolve, reject) => {
        axios.get('/workspaces/with-permitted-access/').then(response => {
          context.commit('updateUserWorkspacesWithPermittedAccess', response.data)
          resolve(response.data)
        }).catch(error => reject(error))
      })
    },
    deleteWorkspace(context, { formData, workspaceId }) {
      return new Promise((resolve, reject) => {
        axios.patch(`/workspaces/delete/${workspaceId}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setSettingsWorkspace(context, formData) {
      return new Promise((resolve, reject) => {
        axios.patch(`/workspaces/update/${formData.get('id')}`, formData)
          .then(response => {
            // context.commit('updateOneOrgWorkspace', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    sendInvitation(context, formData) {
      return new Promise((resolve, reject) => {
        axios.post(`/organizations/invitations/create/${formData.get('organization')}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeInvitation(context, invitationId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/organizations/invitations/delete/${invitationId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    confirmInvitation(context, invitationId) {
      return new Promise((resolve, reject) => {
        axios.get(`/organizations/invitations/confirm/${invitationId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setOtherSettingsWorkspace(context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`/workspaces/update/${data.id}`, data, {
          headers: { 'Content-Type': 'application/json' },
        })
          .then(response => {
            // context.commit('updateOneOrgWorkspace', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    // eslint-disable-next-line consistent-return
    // fetchWorkCategories(context) {
    //   if (context.state.workCategories === null) {
    //     return new Promise((resolve, reject) => {
    //       axios.get('/workspaces/categories/')
    //         .then(response => {
    //           context.commit('updateWorkCategories', response.data)
    //           resolve(response)
    //         })
    //         .catch(error => reject(error))
    //     })
    //   }
    // },
    fetchOrgWorkspaces(context, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/organizations/workspaces/${id}`)
          .then(response => {
            context.commit('UPDATE_ORG_WORKSPACES', response.data.workspace_by_org)
            // localStorage.setItem('timezone', response.data.timezone)
            // context.dispatch('setOrgName', response.data)
            resolve(true)
          })
          .catch(error => reject(error))
      })
    },
    createNewWorkspace(context, formData) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-param-reassign
        axios.post(`/workspaces/create/${formData.get('organization')}`, formData, {
          headers: { 'Content-Type': 'application/json' },
        })
          .then(response => {
            // context.commit('updateActiveAddWorkspace', false)
            // context.dispatch('fetchOrgWorkspaces', response.data.dashboard)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
