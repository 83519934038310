import axios from '@axios'

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    openSocialAuthPage(context, key) {
      axios.get(`social-auth/url/${key}`, { params: key = 'facebook' ? { auth_type: 'reauthenticate' } : { prompt: 'consent' }}).then(response => {
        // Open the window
        window.location.href = response.data.data.url
      })
    },
    sendStateAndCodeProvider(context, urlParams) {
      return new Promise((resolve, reject) => {
        const code = urlParams.get('code')
        const state = urlParams.get('state')
        const provider = urlParams.get('provider')
        const urlLogin = `/social-auth/social/knox_user/${provider}`
        axios.post(urlLogin, {
          code,
          state,
          provider,
        }).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    registerUser(context, formData) {
      return new Promise((resolve, reject) => {
        axios.post('/auth/users/', formData).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    checkEmail(ctx, email) {
      return new Promise((resolve, reject) => {
        axios.post('/token/pre-check', {email: email})
          .then(res => {
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    emailRecovery(ctx, emailAndPassword) {
      return new Promise((resolve, reject) => {
        axios.post('/email/recovery', emailAndPassword)
          .then(res => {
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    resendActivation(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('/auth/users/resend_activation/', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    loginUser(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('/token/login/', data).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    logoutUser() {
      return new Promise((resolve, reject) => {
        axios.post('/token/logout/').then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    resetTokens(context, token) {
      return new Promise((resolve, reject) => {
        axios.post('/token/logoutall/', null, {
          headers: { 'Authorization': `Bearer ${token}` },
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    activateUser(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('/auth/users/activation/', {
          uid: data.uid,
          token: data.token,
        }).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    accountRecovery(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('/profile/recovery', {
          tokenKey: data.tokenKey,
        }).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    logoutSpecificToken(context, {tokenKey, index}) {
      return new Promise((resolve, reject) => {
        axios.post('/token/logout-token', {'tokenKey': tokenKey}).then(response => {
          context.commit('DELETE_USER_DEVICE', index)
          resolve(response)
        }).catch(error => reject(error))
      })
    }
  },
}
