import axios from '@axios'
import i18n from '@/libs/i18n'

export default {
  state: {
    donatData: {
      datasets: [
        {
          labels: [i18n.t('analytics.new-clients'), i18n.t('analytics.repeat-clients'), i18n.t('analytics.repeat-clients-two'), i18n.t('analytics.regular-clients')],
          data: [0, 0, 0, 0],
          backgroundColor: ['#ec2761', '#ffd02f', '#4b66f8', '#009787'],
          borderWidth: 0,
          pointStyle: 'rectRounded',
        },
      ],
    },
    workspacesForSelect: [
      { value: null, text: `${i18n.t('analytics.whole-organization')}` },
    ],
    moneyDates: [],
    moneyCounts: [],
    newChart: [],
    chart: {
      series: [65],
      options: {
        grid: {
          show: false,
          padding: {
            left: -15,
            right: -15,
            top: -12,
            bottom: -15,
          },
        },
        colors: ['#7367F0'],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '22%',
            },
            track: {
              background: '#e9ecef',
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
      },
    },
    // statisticsCardWorkspaces: [],
    lineChartSimple: {
      series: [
        {
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 7,
          strokeOpacity: 1,
          strokeColors: ['#f6f6f6'],
          colors: ['#ff9f43'],
        },
        colors: ['#ff9f43'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          custom(data) {
            return `${'<div class="px-1 py-50"><span>'}${
              data.series[data.seriesIndex][data.dataPointIndex]
            }</span></div>`
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          // opposite: isRtl,
        },
      },
    },
    latestChart: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: '#ec2761',
          borderColor: 'transparent',
        },
      ],
    },
    recordsForThePeriod: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: '#4b66f8',
          borderColor: 'transparent',
        },
      ],
    },
    // clientsCount: [0, 0, 0, 0],
    recordsByDayWeekCount: {
      xAxis: [
        {
          type: 'value',
        },
      ],
      yAxis: [
        {
          type: 'category',
          data: [
            i18n.t('weekday.monday'),
            i18n.t('weekday.tuesday'),
            i18n.t('weekday.wednesday'),
            i18n.t('weekday.thursday'),
            i18n.t('weekday.friday'),
            i18n.t('weekday.saturday'),
            i18n.t('weekday.sunday'),
          ],
          splitLine: { show: false },
        },
      ],
      grid: {
        left: '100px',
        right: '30px',
        bottom: '30px',
      },
      series: [
        {
          name: '6:00-9:00',
          type: 'bar',
          stack: 'advertising',
          data: [0, 0, 0, 0, 0, 0, 0],
          barWidth: '30%',
        },
        {
          name: '9:00-12:00',
          type: 'bar',
          stack: 'advertising',
          data: [0, 0, 0, 0, 0, 0, 0],
          barWidth: '30%',
        },
        {
          name: '12:00-15:00',
          type: 'bar',
          stack: 'advertising',
          data: [0, 0, 0, 0, 0, 0, 0],
          barWidth: '30%',
        },
        {
          name: '15:00-18:00',
          type: 'bar',
          stack: 'advertising',
          data: [0, 0, 0, 0, 0, 0, 0],
          barWidth: '30%',
        },
        {
          name: '18:00-21:00',
          type: 'bar',
          stack: 'advertising',
          data: [0, 0, 0, 0, 0, 0, 0],
          barWidth: '30%',
        },
        {
          name: '21:00-24:00',
          type: 'bar',
          stack: 'advertising',
          data: [0, 0, 0, 0, 0, 0, 0],
          barWidth: '30%',
          itemStyle: {
            barBorderRadius: [0, 10, 10, 0],
          },
        },
      ],
    },
  },
  getters: {
    getDonatData(state) {
      return state.donatData
    },
    getWorkspacesForSelect(state) {
      return state.workspacesForSelect
    },
    getMoneyCounts(state) {
      return state.moneyCounts
    },
    getMoneyDates(state) {
      return state.moneyDates
    },
    getLineChartSimple(state) {
      return state.lineChartSimple
    },
    getLatestChart(state) {
      return state.latestChart
    },
    getRecordsForThePeriod(state) {
      return state.recordsForThePeriod
    },
    getRecordsByDayWeekCount(state) {
      return state.recordsByDayWeekCount
    },
  },
  mutations: {
    UPDATE_DONAT_DATA(state, data) {
      state.donatData.datasets[0].data = data
    },
    updateWorkspacesForSelect(state, data) {
      state.workspacesForSelect = data
    },
    updateMoneyCounts(state, data) {
      state.moneyCounts = data
    },
    updateMoneyDates(state, data) {
      state.moneyDates = data
    },
    updateLineChartSimpleData(state, data) {
      state.lineChartSimple.series[0].data = data
    },
    updateVisitsCategories(state, data) {
      state.lineChartSimple.chartOptions.xaxis.categories = data
    },
    updateLatestBarChartData(state, data) {
      state.latestChart.datasets[0].data = data
    },
    updateLatestBarChartLabels(state, data) {
      state.latestChart.labels = data
    },
    updateRecordsForThePeriodData(state, data) {
      state.recordsForThePeriod.datasets[0].data = data
    },
    updateRecordsForThePeriodLabels(state, data) {
      state.recordsForThePeriod.labels = data
    },
    updateRecordsByDayWeekCount(state, data) {
      state.recordsByDayWeekCount.series = data
    },
  },
  actions: {
    fetchAnalyticsForToday(context, organization) {
      return new Promise((resolve, reject) => {
        axios.get(`organizations/analytics/today/${organization}/`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchRecordsByDayOfTheWeekCount(context, { organization, period }) {
      const start = period[0]
      const now = period[period.length - 1]
      return new Promise((resolve, reject) => {
        if (start && now) {
          axios.get(`organizations/analytics/by-day-of-the-week/${organization}/?start=${start}&end=${now}`)
            .then(response => {
              context.commit('updateRecordsByDayWeekCount', response.data)
              // context.dispatch('setOrgName', response.data)
              resolve(response)
            })
            .catch(error => reject(error))
        }
      })
    },
    fetchAnalyticsMoney(context, { organization, period, workspace }) {
      const start = period[0]
      const now = period[period.length - 1]
      return new Promise((resolve, reject) => {
        if (start && now) {
          axios.get(`organizations/analytics/money/${organization}?start=${start}&end=${now}&workspace=${workspace}`)
            .then(response => {
              const counts = []
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < period.length; i++) {
                if (response.data.records_by_org.dates.indexOf(period[i]) !== -1) {
                  counts[i] = response.data.records_by_org.counts[response.data.records_by_org.dates.indexOf(period[i])]
                } else {
                  counts[i] = 0
                }
              }
              context.commit('updateMoneyCounts', counts)
              context.commit('updateMoneyDates', period)
              resolve(response)
            })
            .catch(error => reject(error))
        }
      })
    },
    fetchAnalyticsLinksAndDevices(context, { organization, period }) {
      const start = period[0]
      const now = period[period.length - 1]
      return new Promise((resolve, reject) => {
        if (start && now) {
          axios.get(`organizations/analytics/visits-link/${organization}?start=${start}&end=${now}`)
            .then(response => {
              resolve(response)
            })
            .catch(error => reject(error))
        }
      })
    },
    fetchAnalyticsServices(context, { organization, period }) {
      const start = period[0]
      const now = period[period.length - 1]
      return new Promise((resolve, reject) => {
        if (start && now) {
          axios.get(`organizations/analytics/services/${organization}?start=${start}&end=${now}`)
            .then(response => {
              resolve(response)
            })
            .catch(error => reject(error))
        }
      })
    },
    fetchClientsCount(context, { organization, specialist }) {
      return new Promise((resolve, reject) => {
        axios.get(`organizations/analytics/clients-count/${organization}?workspace=${specialist}`)
          .then(response => {
            // context.commit('updateWorkspacesForSelect', response.data.workspace_by_org)
            context.commit('UPDATE_DONAT_DATA', response.data.clients_by_org.clients_count)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    // eslint-disable-next-line consistent-return
    fetchSessionsOnThePeriod(context, { organization, period }) {
      const start = period[0]
      const now = period[period.length - 1]
      if (start && now) {
        return new Promise((resolve, reject) => {
          axios.get(`organizations/analytics/visits/${organization}?start=${start}&end=${now}`)
            .then(response => {
              const counts = []
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < period.length; i++) {
                if (response.data.visits_by_org.dates.indexOf(period[i]) !== -1) {
                  counts[i] = response.data.visits_by_org.counts[response.data.visits_by_org.dates.indexOf(period[i])]
                } else {
                  counts[i] = 0
                }
              }
              context.commit('updateLineChartSimpleData', counts)
              context.commit('updateVisitsCategories', period)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      }
    },
    // eslint-disable-next-line consistent-return
    fetchNumberOfRecordsMadePerPeriod(context, { organization, period }) {
      const start = period[0]
      const now = period[period.length - 1]
      if (start && now) {
        return new Promise((resolve, reject) => {
          axios.get(`organizations/analytics/recording-made/${organization}/?start=${start}&end=${now}`)
            .then(response => {
              const counts = []
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < period.length; i++) {
                if (response.data.records_by_org.dates.indexOf(period[i]) !== -1) {
                  counts[i] = response.data.records_by_org.counts[response.data.records_by_org.dates.indexOf(period[i])]
                } else {
                  counts[i] = 0
                }
              }
              context.commit('updateLatestBarChartData', counts)
              context.commit('updateLatestBarChartLabels', period)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      }
    },
    // eslint-disable-next-line consistent-return
    fetchNumberOfRecordsPerPeriod(context, { organization, period }) {
      const start = period[0]
      const now = period[period.length - 1]
      if (start && now) {
        return new Promise((resolve, reject) => {
          axios.get(`organizations/analytics/records-count/${organization}/?start=${start}&end=${now}`)
            .then(response => {
              const counts = []
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < period.length; i++) {
                if (response.data.records_by_org.dates.indexOf(period[i]) !== -1) {
                  counts[i] = response.data.records_by_org.counts[response.data.records_by_org.dates.indexOf(period[i])]
                } else {
                  counts[i] = 0
                }
              }
              context.commit('updateRecordsForThePeriodData', counts)
              context.commit('updateRecordsForThePeriodLabels', period)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      }
    },
    // stringToDate(_date, _format, _delimiter) {
    //   const formatLowerCase = _format.toLowerCase()
    //   const formatItems = formatLowerCase.split(_delimiter)
    //   const dateItems = _date.split(_delimiter)
    //   const monthIndex = formatItems.indexOf('mm')
    //   const dayIndex = formatItems.indexOf('dd')
    //   const yearIndex = formatItems.indexOf('yyyy')
    //   // eslint-disable-next-line radix
    //   let month = parseInt(dateItems[monthIndex])
    //   month -= 1
    //   return new Date(dateItems[yearIndex], month, dateItems[dayIndex])
    // },
    setRangeFromToday() {
      return new Promise(resolve => {
        let now = new Date()
        let start = new Date()
        start.setDate(start.getDate() - 6)
        start = `${start.getFullYear()}-${(`0${start.getMonth() + 1}`).slice(-2)}-${(`0${start.getDate()}`).slice(-2)}`
        now = `${now.getFullYear()}-${(`0${now.getMonth() + 1}`).slice(-2)}-${(`0${now.getDate()}`).slice(-2)}`
        resolve(`${start} to ${now}`)
      })
    },
    createArrayPeriod(context, rangePicker) {
      return new Promise(resolve => {
        const period = []
        const date1 = new Date(rangePicker.slice(0, 10))
        const date2 = new Date(rangePicker.slice(14, 24))
        while (date1 <= date2) {
          period.push(`${date1.getFullYear()}-${(`0${date1.getMonth() + 1}`).slice(-2)}-${(`0${date1.getDate()}`).slice(-2)}`)
          date1.setDate(date1.getDate() + 1)
        }
        resolve(period)
      })
    },
  },
}
