import axios from '@axios'
// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default {
  state: {
    userRecords: null,
    fullCalendarOptions: [],
    timeslots: [],
    countdown: '',
    isVisibleOverlayCalendar: false,
  },
  getters: {
    getUserRecords(state) {
      return state.userRecords
    },
    getFullCalendarOptions(state) {
      return state.fullCalendarOptions
    },
    getTimeslots(state) {
      return state.timeslots
    },
    getCountdown(state) {
      return state.countdown
    },
    getIsVisibleOverlayCalendar(state) {
      return state.isVisibleOverlayCalendar
    }
  },
  mutations: {
    SET_OVERLAY_VISIBILITY(state, isVisible) {
      state.isVisibleOverlayCalendar = isVisible;
    },
    updateUserRecords(state, data) {
      state.userRecords = data
    },
    updateFullCalendarOptions(state, data) {
      state.fullCalendarOptions = data
    },
    UPDATE_COUNTDOWN(state, data) {
      state.countdown = data
    },
    UPDATE_TIMESLOTS(state, data) {
      state.timeslots = data
    }
  },
  actions: {
    fetchTimeslotsAndCountdown(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/workspaces/timeslots/single-day/${data.workspace}`, data)
          .then(response => {
            context.commit('UPDATE_COUNTDOWN', response.data.countdown)
            context.commit('UPDATE_TIMESLOTS', response.data.timeslots)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    // fetchAvailableServices(context, data) {
    //   return new Promise((resolve, reject) => {
    //     axios.post(`/workspaces/services-countdown/${data.workspace}`, data)
    //       .then(response => {
    //         context.commit('updateServices', response.data.services)
    //         context.commit('UPDATE_COUNTDOWN', response.data.countdown)
    //         resolve(response)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },
    updateRecordLikeAdmin(context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`/workspaces/records/update/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    resizeAndDropRecordLikeAdmin(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/workspaces/records/move/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createRecordLikeAdmin(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/workspaces/records/create/${data.workspace}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRecordLikeAdmin(context, recordId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/workspaces/records/delete/${recordId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setRecordLikeGuest(context, formData) {
      return new Promise((resolve, reject) => {
        axios.post(`/storefront/records/create/${formData.workspace}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUserRecords(context) {
      return new Promise((resolve, reject) => {
        axios.get('/workspaces/records/my/').then(response => {
          context.commit('updateUserRecords', response.data)
          resolve(response.data)
        }).catch(error => reject(error))
      })
    },
    fetchTimeslotsForList(context, formData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/workspaces/records/for-list/${formData.get('workspace')}?date=${formData.get('date')}`)
          .then(response => {
            context.commit('updateServices', response.data.services)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchFullCalendarOptions(context, idOrg) {
      context.commit('calendar/SET_SELECTED_EVENTS', [])
      return new Promise((resolve, reject) => {
        axios.get(`/organizations/workspaces/for-calendar/${idOrg}`)
          .then(response => {
            // context.dispatch('setOrgName', response.data)
            context.commit('updateFullCalendarOptions', response.data.workspace_by_org)
            if (response.data.workspace_by_org.length === 0) {
              store.commit('calendar/SET_SELECTED_EVENTS', [])
              resolve(true)
            }
            // localStorage.setItem('timezone', response.data.timezone)
            resolve(true)
          })
          .catch(error => reject(error))
      })
    },
    fetchFullCalendarOptionsPermittedAccess(context) {
      context.commit('calendar/SET_SELECTED_EVENTS', [])
      return new Promise((resolve, reject) => {
        axios.get('/workspaces/with-permitted-access/').then(response => {
          context.commit('updateFullCalendarOptions', response.data)
          resolve(response.data)
        }).catch(error => reject(error))
      })
    },
    saveRecordsToFile(context, formData) {
      return new Promise((resolve, reject) => {
        axios.post(`/workspaces/export/${formData.get('organization')}`, formData).then(response => {
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    // sendEmailForChanges(context, data) {
    //   return new Promise((resolve, reject) => {
    //     axios.post(`/workspaces/records/update-send/${data.id}`, data).then(response => {
    //       resolve(response)
    //     }).catch(error => reject(error))
    //   })
    // }
  },
}
