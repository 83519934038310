import { render, staticRenderFns } from "./ToastificationContent.vue?vue&type=template&id=55dd3057&scoped=true&"
import script from "./ToastificationContent.vue?vue&type=script&lang=js&"
export * from "./ToastificationContent.vue?vue&type=script&lang=js&"
import style0 from "./ToastificationContent.vue?vue&type=style&index=0&id=55dd3057&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55dd3057",
  null
  
)


    import installComponents from "!/app/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BAvatar} from 'bootstrap-vue'
    installComponents(component, {BAvatar})
    

export default component.exports