import axios from '@axios'

export default {
  state: {
    infoWorkspace: '',
    reviews_last_org_id: null,
    reviewsPagination: {},
    reviews: [],
    reviewsSettingsPagination: {
      page: 1,
      size: 10,
      order: 'id',
      query: '',
    },
  },
  getters: {
    getInfoWorkspace(state) {
      return state.infoWorkspace
    },
    getReviewsPagination(state) {
      return state.reviewsPagination
    },
    getReviews(state) {
      return state.reviews
    },
    getReviewsSettingsPagination(state) {
      return state.reviewsSettingsPagination
    },
    getReviewsLastOrgId(state) {
      return state.reviews_last_org_id
    },
  },
  mutations: {
    UPDATE_INFO_WORKSPACE(state, data) {
      state.infoWorkspace = data
    },
    UPDATE_REVIEWS_PAGINATION(state, data) {
      state.reviewsPagination = data
    },
    UPDATE_REVIEWS(state, data) {
      state.reviews = data
    },
    UPDATE_REVIEWS_SETTINGS_PAGINATION(state, {key, value}) {
      state.reviewsSettingsPagination[key] = value
    },
    UPDATE_REVIEWS_LAST_ORG_ID(state, data) {
      state.reviews_last_org_id = data
    },
  },
  actions: {
    createReview(context, formData) {
      return new Promise((resolve, reject) => {
        axios.post('/storefront/workspaces/review/create/', formData)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchLogoOrgAndSpecialist(context, workspace) {
      return new Promise((resolve, reject) => {
        axios.get(`/storefront/workspaces/review/info/${workspace}`)
          .then(response => {
            context.commit('UPDATE_INFO_WORKSPACE', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchReviews(context, formData) {
      return new Promise((resolve, reject) => {
        axios.get(`/workspaces/reviews/${formData.get('organization')}?order=${formData.get('order')}&page=${formData.get('page')}&page_size=${formData.get('size')}&query=${formData.get('query')}`)
          .then(response => {
            context.commit('UPDATE_REVIEWS_LAST_ORG_ID', formData.get('organization'))
            context.commit('UPDATE_REVIEWS_PAGINATION', response.data.pagination)
            context.commit('UPDATE_REVIEWS', response.data.result)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
