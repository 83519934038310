import Cryptojs from 'crypto-js'

export default class crypto {
  static encrypt(data) {
    return Cryptojs.AES.encrypt(JSON.stringify(data), process.env.VUE_APP_ENCRYPTION_KEY).toString()
  }
  static decrypt(data) {
    return Cryptojs.AES.decrypt(data, process.env.VUE_APP_ENCRYPTION_KEY).toString(Cryptojs.enc.Utf8)
  }
  static encryptDataAndSaveToLocalStorage(key, data) {
    return new Promise((resolve, reject) => {
      try {
        if (data) {
          const stringData = typeof data === 'object' ? JSON.stringify(data) : data
          const encryptData = Cryptojs.AES.encrypt(stringData, process.env.VUE_APP_ENCRYPTION_KEY).toString()
          localStorage.setItem(key, encryptData)
          resolve(true)
        } else {
          resolve(false)
        }
      } catch (error) {
        reject(error)
      }
    })
  }
  static decryptLocalStorageData(key) {
    let decryptData = null
    if (localStorage.getItem(key)) {
      const localStorageData = localStorage.getItem(key)
      decryptData = Cryptojs.AES.decrypt(localStorageData, process.env.VUE_APP_ENCRYPTION_KEY).toString(Cryptojs.enc.Utf8)
    }
    return decryptData
  }
}
