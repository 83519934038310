import Vue from 'vue'
import {
  ToastPlugin, ModalPlugin, SidebarPlugin, IconsPlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueCryptojs from 'vue-cryptojs'

// acl
// import '@/libs/acl'
import i18n from '@/libs/i18n'
import VueMask from 'v-mask'
import firebaseMessaging from './firebase'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

//phone input
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
// google maps
import GmapVue from 'gmap-vue'

// BSV Plugin Registration
Vue.use(VueCryptojs)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(SidebarPlugin)
Vue.use(IconsPlugin)
Vue.use(VueTelInput)
// v-mask
Vue.use(VueMask)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import icon wizard form
require('@core/assets/fonts/feather/iconfont.css')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = process.env.NODE_ENV

Vue.prototype.$messaging = firebaseMessaging

Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
    // v: '3.26',
    customCallback: 'MyCustomCallback',
  },
  autoBindAllEvents: false,
  installComponents: true,
  dynamicLoad: false,
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
