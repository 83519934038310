import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import auth from '@/store/modules/auth'
import communication from '@/store/modules/communication'
import profile from '@/store/modules/profile'
import statistics from '@/store/modules/statistics'
import booking from '@/store/modules/booking'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import organization from './modules/organization'
import workspace from './modules/workspace'
// eslint-disable-next-line import/no-cycle
import record from './modules/record'
import location from './modules/location'
import websocket from './modules/websocket'
import widget from './modules/widget'
import review from './modules/review'
import clientsBase from './modules/clientsBase'
import daysOff from './modules/daysOff'
import service from '@/store/modules/service'
import branch from '@/store/modules/branch'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    app,
    appConfig,
    verticalMenu,
    profile,
    organization,
    workspace,
    record,
    location,
    statistics,
    booking,
    communication,
    websocket,
    widget,
    review,
    clientsBase,
    daysOff,
    service,
    branch,
  },
  strict: process.env.DEV,
})
